
function getEventDatabase() {
    return {
        '2d': {
            id: '2d',
            kind: 'iData mozizás (2D)',
            category: 'iData mozizás',
            emoji: '\u{1F37F}',
            description:
                'Ez egy szimpla, nem 3D mozifilm, nem kell 3D vagy IMAX szemüveg. A film kezdete előtt 30 perccel érdemes megjelenni a kukorica miatt. Kérlek ne késs. Ha mégis késel, a jegyeidet elrejtjük az édességes pult melletti plakátok közül a jobbra eső jobb széle alá, onnan begyűjtheted.'
        },
        '3d': {
            id: '3d',
            kind: 'iData mozizás (3D)',
            category: 'iData mozizás',
            emoji: '\u{1F37F}',
            description:
                'Ez egy 3D mozifilm, nem IMAX. Szabvány nem IMAX 3D szemüveg szükséges. Amennyiben van szemüveged, hozdd magaddal. Ha van felesleges szemüveged, hozdd magaddal, ha valaki kéri. Kérj meg valakit, hogy hozzon neked szemüveget. Amennyiben szeretnél venni, külön válaszd ki, hány 3D-s szemüveget kérsz. Ennek az ára 350 Ft / db. A film kezdete előtt 30 perccel érdemes megjelenni a kukorica miatt. Kérlek ne késs. Ha mégis késel, a jegyeidet elrejtjük az édességes pult melletti plakátok közül a jobbra eső jobb széle alá, onnan begyűjtheted.',
            extraFields: { 'gogglesCount': { name: '3D szemüveg' } }
        },
        'imax': {
            id: 'imax',
            kind: 'iData mozizás (IMAX)',
            category: 'iData mozizás',
            emoji: '\u{1F37F}',
            description:
                'Ez egy IMAX mozifilm, nem szimpla 3D. Szabvány IMAX 3D szemüveg szükséges, sima 3D-s nem jó. Amennyiben van szemüveged, hozdd magaddal. Ha van felesleges szemüveged, hozdd magaddal, ha valaki kéri. Kérj meg valakit, hogy hozzon neked szemüveget. Amennyiben szeretnél venni, külön válaszd ki, hány 3D-s szemüveget kérsz. Ennek az ára 350 Ft / db. A film kezdete előtt 30 perccel érdemes megjelenni a kukorica miatt. Kérlek ne késs. Ha mégis késel, a jegyeidet elrejtjük az édességes pult melletti plakátok közül a jobbra eső jobb széle alá, onnan begyűjtheted.',
            extraFields: { 'gogglesCount': { name: 'IMAX szemüveg' } }
        },
        'theater': {
            id: 'theater',
            kind: 'iData színház',
            category: 'iData színház',
            emoji: '\u{1F3AD}',
            description:
                'Ez egy színházi előadás. Kérlek vedd figyelembe, hogy ezeket a jegyeket jóval előre meg kell venni, ezért ne halogasd a jelentkezésedet, illetve lezárás utáni lemondás esetén neked kell eladnod a jegyedet.'
        },
        'dumaszinhaz': {
            id: 'dumaszinhaz',
            kind: 'iData dumaszínház',
            category: 'iData dumaszínház',
            emoji: '\u{1F602}',
            description:
                'Ez egy dumaszínház előadás. Kérlek vedd figyelembe, hogy ezeket a jegyeket jóval előre meg kell venni, ezért ne halogasd a jelentkezésedet, illetve lezárás utáni lemondás esetén neked kell eladnod a jegyedet.'
        },
        'party': {
            id: 'party',
            kind: 'iData parti',
            category: 'iData parti',
            emoji: '\u{1F388}',
            description:
                'A céges partikat a közösségi helyiségben rendezzük. Lesz friss ennivaló és üdítő, illetve bor és sör is :) A rendezvény kifulladásig tart. Csak munkatársak vehetnek részt az eseményen.'
        },
        'movieclub': {
            id: 'movieclub',
            kind: 'iData filmklub',
            category: 'iData filmklub',
            emoji: '\u{1F3AC}',
            description:
                'Kérlek szavazz az infó linken a filmre, amit szeretnél nézni, és ne késs el. Lesz mogyi, ropi, chips és üdítő :) Várunk!'
        },
        'trip': {
            id: 'trip',
            kind: 'iData kirándulás',
            category: 'iData kirándulás',
            emoji: '\u{1F3DE}',
            description:
                'Mindenképp olvass el mindent az egyéb infó oldalon, a megadott helyszín nem feltétlenül az indulás pontos helye.'
        },
        'spring': {
            id: 'spring',
            kind: 'iData Spring party',
            category: 'iData Spring party',
            emoji: '🎮',
            description:
                'Töltsd le a springrts.com-ról, és a Balanced Annihilationt fogjuk játszani E323 és KAIK AI-ok ellen.'
        },
        'cs': {
            id: 'cs',
            kind: 'iData Counter Strike Party',
            category: 'iData Counter Strike Party',
            emoji: '🎮',
            description:
                'Gyere be, ha lehet, hozz gépet. Ha nem lesz elég, szívás :) Van kincstári játszós USB-s oprendszerünk, nem kell készülni semmivel.'
        },
    };
}

function getEventTypes() {
    var allEventTypes = Object.getOwnPropertyNames(getEventDatabase());
    return allEventTypes;
}

function getEventData(eventType) {
    return getEventDatabase()[eventType];
}

function getDefaultEvent() {
    return {
        eventType: "2d",
        description: "",
        location: "Aréna Pláza",
        locationUrl: "https://www.google.hu/maps/place/Arena+Plaza/@47.498224,19.0899307,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc8e90ae347f:0x79ad6c8610deab9a!8m2!3d47.498224!4d19.0921194",
        moreUrl: "",
        price: 0,
        startsAt: new Date(),
        startsUncertain: false,
        title: "",
        state: "open",
        visible: false,
        targetEmail: "mindenki@idata.hu",
        bannerUrl: "",
        posterUrl: "",
        phone: "06-",
    };
}

function getUserInfoObject(user) {
    return {
        uid: user.uid,
        email: user.email,
        photoURL: user.photoURL,
        displayName: user.displayName
    };
}

module.exports.getEventData = getEventData;
module.exports.getEventDatabase = getEventDatabase;
module.exports.getEventTypes = getEventTypes;
module.exports.getDefaultEvent = getDefaultEvent;
module.exports.getUserInfoObject = getUserInfoObject;