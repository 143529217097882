import React, { Component, useState } from 'react';
import { Card, CardBody, Input, Form, FormGroup, Label, Button } from 'reactstrap';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import firebaseApp from './firebase';
/*import {
    Link
} from 'react-router-dom';*/
import { ClimbingBoxLoader } from 'react-spinners';
import $ from 'jquery';
import * as utils from './utils';
import * as toastr from 'toastr';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';

import Event from './Event';

var eventdata = require('./eventdata');


class EventEditor extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            display: props.display,
            loading: true,
            saving: false,
            id: props.match.params.id,
            editorVisible: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleReenableEditing = this.handleReenableEditing.bind(this);
        this.generateGoogleMapsLink = this.generateGoogleMapsLink.bind(this);
    }

    fetchEvent(success, error) {
        console.log("fetch event " + this.state.id);
        var db = getFirestore(firebaseApp);
        var _this = this;
        getDoc(doc(db, "events", this.state.id)).then((docRef) => {
            if (docRef.exists) {
                success(docRef);
            } else {
                toastr.error("Nem sikerült betölteni az eseményt");
            }
        });
    }

    componentDidMount() {
        if (!this.state.event) {
            if (this.state.id && this.state.id !== "new") {
                this.fetchEvent((docRef)=>{
                    var newEvent = docRef.data();
                    newEvent.id = docRef.id;
                    if (newEvent.rsvps) {
                        delete newEvent.rsvps;
                    }
                    newEvent.startsAt = newEvent.startsAt.toDate();
                    console.log(JSON.stringify(newEvent));
                    this.setState({
                        event: newEvent,
                        id: docRef.id,
                        loading: false,
                        docRef: docRef
                    });
                });
            } else { // this.state.id === "new"
                console.log("preparing new event");
                var newEvent = eventdata.getDefaultEvent();
                newEvent.organizer = eventdata.getUserInfoObject(getAuth().currentUser);
                this.setState({
                    event: newEvent,
                    loading: false
                });
                toastr.info("Nagy öröm lesz mindenkinek, hogy új eseményt csinálsz :)", "Hajrá!");
            }
        }
        console.log(this.state.event);
    }


    handleChange(event) {
        var changedState = this.state;
        if (event instanceof Date) {
            console.log("handleChange date: " + event);
            changedState["event"].startsAt = event;
        } else if (event.target.id === "startUncertain" || event.target.id === "visible") {
            console.log("handleChange: " + event.target.id + " = " + event.target.checked);
            changedState["event"][event.target.id] = event.target.checked;
        } else {
            console.log("handleChange: " + event.target.id + " = " + event.target.value);
            changedState["event"][event.target.id] = event.target.value;
        }
        this.setState(changedState);
    }


    handleDelete() {
        console.log("delete pressed");
        toastr.info("Hívd Andrást a +36302516551 számon a törléshez, addig is rejtsd el az eseményt, a rejtett eseményeidet csak te látod.", 
            "Még csak András tud törölni", { timeOut: 15000 });
    }

    handleSave(redirect) {
        console.log("saving");
        console.log(this.state.event);
        var db = getFirestore(firebaseApp);
        var _this = this;
        if (this.state.id === "new") {
            console.log("this is a new event, we have to create it and update id in state");
            var eventToUpdate = this.state.event;
            delete eventToUpdate.rsvps;
            let newId = uuidv4();
            setDoc(doc(db, "events", newId), eventToUpdate).then(() => {
                console.log("event added to db, updating state");
                var changedState = _this.state;
                changedState.id = newId;
                changedState.event.id = newId;
                _this.setState(changedState);
                toastr.success("Egyelőre nem látható az eseményed! Ne feledd megnyitni, és kiküldeni az értesítő levelet!", 
                    "Új esemény létrehozva!", { timeOut: 10000 });
                _this.handleDisableEditing();
                window.location = "/editevent/" + newId; // FIXME later to use router
            }).catch((error) => {
                console.error("Nem sikerült az új esemény létrehozása :(", error);
                toastr.error("Nem sikerült az új esemény létrehozása :(", error);
            });
        } else if (this.state.id) {
            console.log("updating event " + this.state.id);
            var eventToUpdate = this.state.event;
            delete eventToUpdate.rsvps;
            
            updateDoc(doc(db, "events", this.state.id), eventToUpdate).then((docRef) => {
                toastr.success("Ne feledd megnyitni, és kiküldeni az értesítő levelet!", 
                    "Esemény módosítva!", { timeOut: 6000 });
                _this.handleDisableEditing();
            }).catch((error) => {
                toastr.error("Nem sikerült a frissítés! :(", error);
            });
        } else {
            toastr.error("Valami gáz van", "nem szabályos az id!");
        }
    }


    handleDisableEditing() {
        var changedState = this.state;
        changedState.editorVisible = false;
        this.setState(changedState);
        setTimeout(() => {
            document.getElementById('previewstartshere')
                .scrollIntoView({block: 'start', behavior: 'smooth'});
        }, 100);
    }

    handleReenableEditing() {
        var changedState = this.state;
        changedState.editorVisible = true;
        this.setState(changedState);
        setTimeout(() => {
            document.getElementById('editstartshere')
                .scrollIntoView({block: 'start', behavior: 'smooth'});
        }, 100);
    }

    generateGoogleMapsLink() {
        var changedState = this.state;
        changedState.event.locationUrl = "https://www.google.hu/maps?q=" + encodeURI(this.state.event.location);        
        this.setState(changedState);
    }

    /*

        eventType: "3d",
        organizer: {
            uid: "itPvLQl3oDdsn0nPzYdSt6RccMx1",
            email: "szundi@gmail.com",
            displayName: "Szabó András",
            photoURL: "https://lh6.googleusercontent.com/-BURjVcRdyzo/AAAAAAAAAAI/AAAAAAAAAYo/Aj9MbH86ZvU/photo.jpg",
        },
        description: "Kvák kvák.",
        location: "Aréna Pláza",
        locationUrl: "https://www.google.hu/maps/place/Arena+Plaza/@47.498224,19.0899307,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc8e90ae347f:0x79ad6c8610deab9a!8m2!3d47.498224!4d19.0921194",        
        moreUrl: "http://www.imdb.com/title/tt4209788",        
        price: 2190,
        startsAt: admin.firestore.FieldValue.serverTimestamp(),
        title: "Mozifilm címe, töltsd ki",
        state: "open",
        targetEmail: "szundi@gmail.com",
        bannerUrl: "https://t4.ftcdn.net/jpg/00/56/88/63/240_F_56886358_ARQGdLdk9Jjd5Y0qMQumwlekBuUR6wI7.jpg",
        posterUrl: "https://www.featurepics.com/StockImage/20130515/empty-poster-stock-picture-2701512.jpg",

    */

    renderIfLoaded() {
        const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        if (this.state.loading) return "";
        return (
        <React.Fragment>
            {this.state.editorVisible ? 
            <Card><CardBody>
                <Form>
                    <FormGroup>
                        <Label id="editstartshere" for="title">Cím</Label>
                        <Input id="title" type="text" value={this.state.event.title} onChange={this.handleChange} className="form-control" />
                    </FormGroup>
                    <FormGroup>
                        <Input id="visible" type="checkbox" value={this.state.event.visible} defaultChecked={this.state.event.visible} 
                            onChange={this.handleChange} className="form-control" /> Az esemény mások számára látható
                    </FormGroup> 
                    <FormGroup>
                        <Label>Jelentkezések engedélyezve:</Label>
                        <Input id="state" type="select" value={this.state.event.state} onChange={this.handleChange} className="form-control" >
                            <option value="open">Nyitva: lehet jelentkezni</option>
                            <option value="closed">Zárva: jelentkezés módosítás tiltott</option>
                        </Input>   
                        <p><small className='text-muted'>A jelentkezéseket érdemes lezárni, mielőtt a jegyeket megrendeled: így elkerülhető, hogy később valaki jelentkezik vagy visszavonja a jelentkezést, és ebből féreértések adódnának.</small></p>
                    </FormGroup>
                    <FormGroup>
                        <Label>Esemény típusa: {this.state.event.eventType ? ""+eventdata.getEventData(this.state.event.eventType).emoji : ""}</Label>                        
                        <Input id='eventType' type='select' value={this.state.event.eventType} onChange={this.handleChange} className='form-control' >
                            {eventdata.getEventTypes().map( eventType => 
                                    <option value={eventType} key={eventType} >{eventdata.getEventData(eventType).kind}</option>
                                )}
                        </Input>   
                    </FormGroup>
                    <FormGroup>
                        <Label>Banner kép URL:</Label>
                        <Input id='bannerUrl' type='text' value={this.state.event.bannerUrl} onChange={this.handleChange} className='form-control' />
                        <p><small className='text-muted'>Keress egy banner-t a Google-lel, pl. 'star wars banner', majd a jobbgomb, képcím másolása után ide vágd be.</small></p>
                        <img width='100%' src={this.state.event.bannerUrl} alt='Event banner' />
                        </FormGroup> 
                    <FormGroup>
                        <Label>Esemény leíró szöveg:</Label>
                        <Input id='description' type='textarea' value={this.state.event.description} onChange={this.handleChange} 
                                    rows={8} width='100%' className='form-control'/>
                    </FormGroup> 
                    <FormGroup>
                        <Label>Helyszín megnevezése:</Label>
                        <Input id='location' type='text' value={this.state.event.location} onChange={this.handleChange} className='form-control' />
                        <Button onClick={this.generateGoogleMapsLink}>Google Maps link generálás</Button>
                    </FormGroup> 
                    <FormGroup>
                        <Label>Helyszín google maps URL:</Label>
                        <Input id="locationUrl" type="text" value={this.state.event.locationUrl} onChange={this.handleChange} className="form-control" />
                    </FormGroup> 
                    <FormGroup>
                        Tesztelheted: <a href={this.state.event.locationUrl} target="_new">{this.state.event.location}</a>
                    </FormGroup> 
                    <FormGroup>
                        <Label>Esemény kezdete: {this.state.event.startsAt.toLocaleDateString("hu", dateFormatOptions)}</Label>
                        <DatePicker 
                            id="startsAt" 
                            selected={this.state.event.startsAt}
                            onChange={this.handleChange} 
                            locale="hu"
                            timeFormat='p'
                            dateFormat='Pp'
                            timeIntervals={15}
                            showTimeSelect
                            />
                    </FormGroup> 
                    <FormGroup>
                        <Input id="startUncertain" type="checkbox" value={this.state.event.startUncertain} 
                            defaultChecked={this.state.event.startUncertain} onChange={this.handleChange} className="form-control" /> Ez az dőpont még változhat
                    </FormGroup> 
                    <FormGroup>
                        <Label>Költség (Ft):</Label>
                        <Input id='price' type='text' value={this.state.event.price} onChange={this.handleChange} className='form-control' />
                    </FormGroup> 
                    <FormGroup>
                        <Label>Szervezési telefonszámod:</Label>
                        <Input id='phone' type='text' value={this.state.event.phone} onChange={this.handleChange} className='form-control' />
                    </FormGroup> 
                    <FormGroup>
                        <Label>Több infó URL, pl. imdb:</Label>
                        <Input id='moreUrl' type='text' value={this.state.event.moreUrl} onChange={this.handleChange} className='form-control' />
                    </FormGroup> 
                    <FormGroup>
                        <Label>Poszter kép URL:</Label>
                        <Input id='posterUrl' type='text' value={this.state.event.posterUrl} onChange={this.handleChange} className='form-control' />
                        <p><small className='text-muted'>Keress egy posztert a Google-lel, pl. 'star wars poster', majd a jobbgomb, képcím másolása után ide vágd be.</small></p>
                    </FormGroup>
                    <FormGroup>
                        <Button color="success" onClick={this.handleSave}>Mentés</Button>
                        <Button color="danger" onClick={this.handleDelete}>Törlés</Button>
                    </FormGroup>
                    <img width="100%" src={this.state.event.posterUrl} alt="Event poster" />
                </Form>
            </CardBody></Card>
            :
            <Card><CardBody>
                <Button id="previewstartshere" color="info" onClick={this.handleReenableEditing}>Szerkesztés tovább...</Button>
            </CardBody></Card>
            }
            <br/>
                <h2>Előnézet:</h2>
            <br/>
            {this.state.event.id ?
                <Event key = 'blahkvák' display =
                    'long' event =
                { this.state.event
                } /> : <Card><CardBody>Mentsd el az eseményedet!</CardBody>
                </Card> }
        </React.Fragment>);
    }



    render() {
        return (
            <React.Fragment>
                <ClimbingBoxLoader color="#888888" loading={this.state.loading} />
                {this.renderIfLoaded()}
            </React.Fragment>
        );
    }
}

export default EventEditor;
