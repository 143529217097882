import React, { Component } from 'react';
import { Label, Input, Button, Form, FormGroup } from 'reactstrap';

class RSVP extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            rsvp: props.rsvp, // pass by reference
            showGoggles: props.showGoggles,
        };
        this.doCancel = this.doCancel.bind(this);
        this.doRsvp = this.doRsvp.bind(this);
    }

    throwRsvpChange(newRsvp) {
        console.log("lifting up rsvp change")
        this.props.handleRsvp(newRsvp);
    }

    doRsvp(event) {
        event.preventDefault();
        this.throwRsvpChange(this.state.rsvp);
    }

    doCancel(event) {
        event.preventDefault();
        var newRsvp = this.state.rsvp;
        newRsvp.participantCount = 0;
        newRsvp.gogglesCount = 0;
        this.setState({rsvp: newRsvp});
        this.throwRsvpChange(newRsvp);
    }

    handleChange(event) {
        var newRsvp = this.state.rsvp;
        newRsvp[event.target.id] = parseInt(event.target.value, 10);
        this.setState({rsvp: newRsvp});
    }

    renderBadRSVPConfigurationWarning() {
        if (this.state.rsvp.participantCount <= 0 && this.state.rsvp.gogglesCount > 0) {
            console.log("more goggles when no participation warning");
            return (
                <div className="alert alert-warning" role="alert">
                    <strong>Vigyázz!</strong> Ha egy jegyet sem rendelsz, akkor nem veszünk neked szemüveget sem, és nem számít a jelentkezésed!
                </div>
            );
        } 
    }

    render () {
        return (
            <Form>
                <FormGroup>
                    <Label for="participantCount">Ennyi jegyet vegyetek nekem:</Label>
                    <Input style={{maxWidth: "10rem"}} type="select" name="participantCount" id="participantCount" 
                            value={this.state.rsvp.participantCount} onChange={this.handleChange.bind(this)}>
                        {[...Array(21)].map((x, i) =>
                            <option key={"rsvpp-"+i}>{i}</option>
                        )}
                    </Input>
                </FormGroup>
                {this.state.showGoggles ? 
                    <FormGroup>
                        <Label for="gogglesCount">Ennyi szemüveget vegyetek nekem:</Label>
                        <Input style={{maxWidth: "10rem"}} type="select" name="gogglesCount" id="gogglesCount" 
                                value={this.state.rsvp.gogglesCount} onChange={this.handleChange.bind(this)}>
                            {[...Array(21)].map((x, i) =>
                                <option key={"rsvpg-"+i}>{i}</option>
                            )}
                        </Input>
                    </FormGroup>
                    : ""
                }
                {this.renderBadRSVPConfigurationWarning()}
                <FormGroup>
                    <Button onClick={this.doRsvp} color="primary">Mentés</Button>
                    <Button onClick={this.doCancel} color="danger">Törlöm magam</Button>
                </FormGroup>
            </Form>
        );
    }
}

export default RSVP;
