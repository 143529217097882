import React, { Component } from 'react';
import { Card, CardImg, CardText, CardBody, CardFooter,
    CardTitle, CardSubtitle, Button, Badge } from 'reactstrap';
import firebaseApp from './firebase';
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import RSVP from './RSVP';
import {
    Link
} from 'react-router-dom';
import { ClimbingBoxLoader, RiseLoader, BarLoader } from 'react-spinners';
import $ from 'jquery';
import * as utils from './utils';
import * as toastr from 'toastr';
import moment from 'moment';
var eventdata = require('./eventdata');


class Event extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            display: props.display,
            loading: true,
            saving: false,
        };
        if (props.event) {
            this.state.event = props.event;
            this.state.loading = false;
        } else if (props.match.params.id) {
            this.state.loading = true;
            this.state.id = props.match.params.id;
        }
        this.handleRsvp = this.handleRsvp.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleSendTestEmail = this.handleSendTestEmail.bind(this);
        // this.handleOpen = this.handleOpen.bind(this);
        // this.handleClose = this.handleClose.bind(this);
        // this.handleHide = this.handleHide.bind(this);
        // this.handleShow = this.handleShow.bind(this);
        this.handleNudges = this.handleNudges.bind(this);
    }

    componentDidMount() {
        if (!this.state.event && this.state.id) {
            this.fetchEvent((docRef)=>{
                var newEvent = docRef.data();
                newEvent.id = docRef.id;
                if (!newEvent.rsvps) {
                    newEvent.rsvps = {};
                }
                newEvent.startsAt = newEvent.startsAt.toDate();
                console.log(JSON.stringify(newEvent));
                this.setState({
                    event: newEvent,
                    id: docRef.id,
                    loading: false,
                    docRef: docRef
                });
            });
        }
    }

    getTruncatedDescription() {
        var length = this.state.event.description.length;
        if (length < 120 || this.state.display==="long") {
            return this.state.event.description;
        } else {
            //return this.state.event.description.substring(0, 100) + "...";
            return "";
        }
    }

    fetchEvent(success, error) {
        console.log("fetch event "+this.state.id);
        var db = getFirestore(firebaseApp);
        var _this = this;
        getDoc(doc(db, "events", this.state.id)).then((docRef) => {
            if (docRef.exists) {
                success(docRef);
            } else {
                error("no doc by id "+_this.state.id);
            }
        });
    }

    calculateSummary() {
        var summary = {
            participantCount: 0,
            gogglesCount: 0
        };
        if (this.state.event.rsvps) {
            for (var key in this.state.event.rsvps) {
                var rsvp = this.state.event.rsvps[key];
                if (rsvp.participantCount > 0) {
                    // deliberately not counting goggles orders without participation orders
                    summary.participantCount += rsvp.participantCount;
                    summary.gogglesCount += rsvp.gogglesCount;
                }
            }
        }
        return summary;
    }


    handleSendTestEmail() {
        this.handleEmail(true);
    }

    handleSendEmail() {
        this.handleEmail(false);
    }

    handleEmail(test) {
        if (test !== true) test = false;
        console.log("handle email, test: " + test);
        if (!test) {
            if (this.state.event.visible !== true) {
                toastr.error("Nem küldhetsz ki emailt láthatatlan eseményről!", "Tedd láthatóvá az eseményt!");
                return;
            }
            if (this.state.event.state !== "open") {
                toastr.error("Nem küldhetsz ki emailt lezárt eseményről!", "Nyisd meg az eseményt!");
                return;
            }
        }
        if (test || window.confirm("Biztos kiküldöd az emailt a "+this.state.event.title+" eseményről?")) {
            console.log("email confirmed, test=" + test);
            $.getJSON(utils.getFunctionsBase()+"/sendEmailAboutNewEvent",
                { eventId: this.state.event.id, test: test }, (data, status) => {
                    console.log("success");
                    console.log(status + " " + JSON.stringify(data));
                    toastr.success("Ellenőrizd a postafiókodat.", "Email kiküldve");
                });
        }
    }

    handleNudges() {
        console.log("handle nudges");
        var _this = this;
        if (window.confirm("Biztos beállítasz figyelmeztető email(eke)t "+this.state.event.title+" eseményről?")) {
            console.log("nudges confirmed");
            console.log("functionsbase: " + utils.getFunctionsBase());
            this.setState({ saving: true });
            $.ajax({
                dataType: "json",
                url: utils.getFunctionsBase()+"/deleteNudgesForEvent",
                data: { eventId: _this.state.event.id }, 
                success: function (result) {
                    console.log("delete nudges is success");
                    toastr.info("Korábbi figyelmeztetések törölve.");
                    $.getJSON(utils.getFunctionsBase()+"/createNudgesForEvent",
                        { eventId: _this.state.event.id }, (data, status) => {
                            console.log("create nudges is success");
                            toastr.success("Újabb figyelmeztetések felvéve.");
                            _this.setState({ saving: false });
                        });
                },
                error: function(result) {
                    console.log(result.responseText);
                }
            });
        }
    }


    renderAdminButtons() {
        if (!this.state.id) {
            // this is just a preview
            return (
                ""
            );
        }
        const auth = getAuth(firebaseApp);
        var userId = auth.currentUser.uid;
        if (userId === this.state.event.organizer.uid) {
            // it is me who organizes this event, i can have my buttons
            return (
                <CardText>
                    <Button color='success' onClick={this.handleSendTestEmail}>Értesítő tesztelés (neked)</Button>
                    <Button color='warning' onClick={this.handleSendEmail}>Értesítő küldés (mindenkinek)</Button>
                    <Button color="warning" onClick={this.handleNudges}>Noszogatás időzítése (mindenkinek)</Button>
{/*                     {(this.state.event.state === 'open') ?
                        <Button color='danger' onClick={this.handleClose}>Lezárás</Button>
                    :
                        <Button color="success" onClick={this.handleOpen}>Megnyitás</Button>
                    }
                    {(this.state.event.visible === true) ?
                        <Button color='danger' onClick={this.handleHide}>Elrejtés</Button>
                    :
                        <Button color="success" onClick={this.handleShow}>Felfedés</Button>
                    }
 */}                    <Link to={{pathname : "/editevent/"+this.state.event.id}}>
                        <Button color='info'>Szerkesztés</Button>
                    </Link>
                </CardText>
            );
        } else return "";
    }


    renderParticipants() {
        return (
            <CardText className="text-muted">
                <small className="text-muted">
                    {Object.keys(this.state.event.rsvps).map( key => 
                        this.state.event.rsvps[key].participantCount > 0 ? this.state.event.rsvps[key].user.displayName + 
                            "(" + this.state.event.rsvps[key].participantCount + 
                            ((this.state.event.eventType === '3d' || this.state.event.eventType === 'imax') ?
                                '/' + this.state.event.rsvps[key].gogglesCount : "" )+ 
                            ') ' : ''
                    )
        }
                </small>
            </CardText>
        );
    }


    handleBack() {
        window.location = "/";
    }


    getEmojiForParticipantCount(count) {
        if (count > 7) return "\u{1F602}";
        if (count > 6) return "\u{1F601}";	
        if (count > 5) return "\u{1F604}";
        if (count > 4) return "\u{1F603}";
        if (count > 3) return "\u{1F600}";
        if (count > 2) return "\u{1F60A}";
        if (count > 1) return "\u{1F642}";
        return "\u{1F642}";
    }

    renderIfLoaded() {
        const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const auth = getAuth(firebaseApp);
        if (this.state.loading) return "";
        return (
            <div>
            <Card>
                <CardBody>
                <CardTitle>
                    {this.state.event.title}
                    {this.state.event.state !== 'open' ? <React.Fragment>&nbsp;<Badge color='secondary'>LEZÁRVA</Badge></React.Fragment> 
                        : <React.Fragment>&nbsp;<Badge color='success'>NYITVA</Badge></React.Fragment> }
                    {this.state.event.visible === false ? <React.Fragment>&nbsp;<Badge color='secondary'>REJTVE</Badge></React.Fragment> 
                        : "" }
                    {(this.calculateSummary().participantCount > 1) ? 
                        <a href="#" className="starburst">
                            <span><span><span>
                                {this.getEmojiForParticipantCount(this.calculateSummary().participantCount)}<br />{this.calculateSummary().participantCount}
                            </span></span></span>
                        </a>
                        : ''
                    }
                </CardTitle>
                <CardSubtitle>
                    {eventdata.getEventData(this.state.event.eventType).kind}
                    {(this.getMyRSVP().participantCount > 0) ?
                        <div className="float-right"><span className="badge badge-success">
                            Jelentkeztél ({this.getMyRSVP().participantCount})</span>
                        </div>
                        : "" 
                    }
                </CardSubtitle>
                </CardBody>
                <CardImg width="100%" src={this.state.event.bannerUrl} alt="Event banner" />
                <CardBody className='text-center-x-x-x'>
                    {(this.state.display === "long") ?
                        <React.Fragment>                 
                            <CardText>{this.state.event.description}</CardText>
                            <CardText>
                                <a href={this.state.event.locationUrl}>{this.state.event.location}</a>,&nbsp; 
                                    {this.state.event.startsAt.toLocaleDateString("hu", dateFormatOptions) + " "}
                                {(this.state.event.startUncertain ? 
                                    <React.Fragment>
                                        <span className="badge badge-warning" data-toggle="tooltip" data-placement="top">
                                            változhat
                                        </span>
                                        <small className="text-muted">
                                            &nbsp;
                                            Az esemény pontos kezdete még változhat pl. a moziműsor kiírása után. A végső időpont a lehető legközelebb lesz a kiírthoz, bátran jelentkezz! Amint véglegesedik, kapsz emailt!
                                        </small>
                                    </React.Fragment>
                                    : ""
                                )}
                            </CardText>
                            <CardText>Költség: <strong>{this.state.event.price} Ft.</strong>, <a href={this.state.event.moreUrl} target="imdbwindow">További infók...</a></CardText>
                            <CardText className="text-muted"><small className="text-muted">Az összeget legkésőbb az esemény napján 15 óráig át kell adni a szervezőnek!</small></CardText>
                            <CardText className="text-muted">
                                    <small className="text-muted">{eventdata.getEventData(this.state.event.eventType).description}</small>
                            </CardText>
                            <CardText>
                                Összes jelentkezés: {this.calculateSummary().participantCount} jegy{
                                    this.state.event.eventType === "3d" || this.state.event.eventType === "imax" ? <span>, {this.calculateSummary().gogglesCount} szemüveg.</span> : "." }
                            </CardText>
                            {this.calculateSummary().participantCount>0 ? this.renderParticipants() : ""}
                            <CardText>
                                Szervező: <a href={"mailto:" + this.state.event.organizer.email + "?subject=" + encodeURI(this.state.event.title)}>
                                    {this.state.event.organizer.displayName}</a>, {this.state.event.phone}
                                <img width={64} height={64} style={{ float: "right" }} src={this.state.event.organizer.photoURL} />
                            </CardText>
                            <CardFooter>                                
                                {this.renderRSVP()}
                                <BarLoader size={100} color="#888888" loading={this.state.saving}></BarLoader>
                                {this.renderAdminButtons()}
                            </CardFooter>
                            <CardImg width="100%" src={this.state.event.posterUrl} alt="Event poster" />
                        </React.Fragment>                 
                        : // short
                        <React.Fragment>
                                <CardText><a href={this.state.event.locationUrl}>{this.state.event.location}</a>, {this.state.event.startsAt.toLocaleDateString("hu", dateFormatOptions)}</CardText>
                            {this.calculateSummary().participantCount>0 ? this.renderParticipants() : ""}
                        </React.Fragment>
                    }
                    {(this.state.display !== "long") ?
                        <CardText>
                            <Link to={{pathname : "/event/"+this.state.event.id}}>
                                <Button type="button" className={this.getMyRSVP().participantCount>0 ? " btn btn-default" : "btn btn-success"}>Részletek és jelentkezés...</Button>
                            </Link>
                            {(this.state.event.organizer.uid === auth.currentUser.uid) ?
                                <React.Fragment>
                                    <Link to={{pathname : "/editevent/"+this.state.event.id}}>
                                        <Button color="info">Szerkesztés</Button>
                                    </Link>
                                </React.Fragment>
                                    : ""
                            }
                        </CardText>
                        : 
                        <CardText>
                            <br/>
                            <Link to={{pathname : "/"}}>
                                <Button type="button" className="btn btn-default">{"\u{1F448}"} Vissza a főoldalra</Button>
                            </Link>
                        </CardText>
                    }
                </CardBody>
            </Card>
            </div>
        );
    }


    handleRsvp(newRsvp) {
        // called only from loaded renderer
        const auth = getAuth(firebaseApp);
        let user = auth.currentUser;
        if (!user) return;

        if (this.state.event.state !== "open") {
            toastr.error("Csak a jelentkezési időszak alatt tudod módosítani ez eseményt, amikor az nyitott. Várd meg a megnyitást, " 
                + "vagy ha már lezáródott, a jegyedet már lehet, hogy megvette a szervező.", "Jelentkezés lezárva", { timeOut: 10000 });
            return;
        }

        console.log("Before:");
        for (var key in this.state.event.rsvps) {
            var rsvp = this.state.event.rsvps[key];
            console.log(rsvp.participantCount);
        }

        var found = false;
        for (key in this.state.event.rsvps) {
            rsvp = this.state.event.rsvps[key];
            if (rsvp.user.uid === newRsvp.user.uid) {
                rsvp.participantCount = newRsvp.participantCount;
                rsvp.gogglesCount = newRsvp.gogglesCount;
                console.log("rsvp new values are updated for "+user.uid);
                found = true;
                break;
            }
        }
        
        if (!found) {
            var newState = this.state;
            if (!newState.event.rsvps) newState.event.rsvps = {};
            newState["event"]["rsvps"][newRsvp.user.uid] = newRsvp;
            this.setState(newState);            
        }

        console.log("After:");
        for (key in this.state.event.rsvps) {
            rsvp = this.state.event.rsvps[key];
            console.log(rsvp.participantCount);
        }

        if (this.state.event) {
            var db = getFirestore(firebaseApp);
            this.setState({ saving: true });
            var newRsvps = {};
            newRsvps["rsvps." + newRsvp.user.uid] = newRsvp;
            console.log("updating event with " + JSON.stringify(newRsvps));
            updateDoc(doc(db, "events", this.state.id), newRsvps).then(() => {
                this.setState({ saving: false });
                console.log("event updated with new rsvp");
                toastr.success("Jelentkezésed frissítve!");
            });
        }
    }


    renderRSVP() {
        // called only from loaded renderer
        const auth = getAuth(firebaseApp);
        let user = auth.currentUser;
        if (!user) return "";

        if (!this.state.id) {
            // this is just a preview
            return (
                ""
            );
        }

        var rsvp = this.getMyRSVP();
        return (
            <RSVP rsvp={rsvp} handleRsvp={this.handleRsvp} showGoggles={this.state.event.eventType==="3d" || this.state.event.eventType==="imax"}/>
        );
    }


    getMyRSVP() {
        const auth = getAuth(firebaseApp);
        let user = auth.currentUser;

        var _rsvp;
        for (var key in this.state.event.rsvps) {
            var rsvp = this.state.event.rsvps[key];
            if (rsvp.user.uid === user.uid) {
                _rsvp=rsvp;
                break;
            }
        }
        if (!_rsvp) {
            _rsvp = {
                user: eventdata.getUserInfoObject(user),
                participantCount: 0,
                gogglesCount: 0
            }
        }
        return _rsvp;
    }


    render() {
        return (
            <div>
                <ClimbingBoxLoader color="#888888" loading={this.state.loading} />
                {this.renderIfLoaded()}
            </div>
        );
    }
}

export default Event;
