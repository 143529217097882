import React, { Component } from 'react';
import firebaseApp from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { Button } from 'reactstrap';
import { collection, doc, setDoc } from "firebase/firestore";
import Events from './Events';
import Event from './Event';
import EventEditor from './EventEditor';
import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import { CircleLoader } from 'react-spinners';


console.log(process.env.NODE_ENV);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            authenticating: true
        };
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        var _this = this;
        const auth = getAuth(firebaseApp);
        onAuthStateChanged(auth, user => {
            if (user) {
                console.log("logged in: " + user.displayName);
                _this.setState({
                    authenticated: true,
                    authenticating: false
                });
            } else {
                console.log("init login check found NO USER, redirecting to google login page");
                var provider = new GoogleAuthProvider();
                auth.languageCode = 'hu';
                signInWithRedirect(auth, provider);
            }
        });
    }

    logout() {
        const auth = getAuth(firebaseApp);
        auth.signOut().then(function() {
            // Sign-out successful.
            console.log("Logout successful.");
            window.location="/";
        }).catch(function(error) {
            // An error happened.
            console.log("Logout failed: " + error);
            window.location="/";
        });
    }

    resetPage() {
        window.location = "/";
    }

    render() {
        const auth = getAuth(firebaseApp);        
        return (
            <Router>
                <div style={{maxWidth: "40rem", margin: "0 auto"}}>
                    <div className="container-fluid h-100 p-5 bg-light border rounded-3">
                        <a onClick={this.resetPage}><img src="/images/pumi_100.png" style={{float:"right"}} /></a>
                        <p className="lead">Pumi, a szervező-kutya!</p>
                        <p>A Pumi összehozza a moziba járókat! Válassz egy eseményt, és jelentkezz rá Te is!</p>
                        
                        <CircleLoader size={100} loading={this.state.authenticating} />

                        {
                            (this.state.authenticated) ?
                                <div>
                                    <Route exact path="/" component={Events}/>
                                    <Route path="/event/:id" component={(props) => (<Event display="long" {...props} />)} />   
                                    <Route path="/editevent/:id" component={(props) => (<EventEditor {...props} />)} />   
                                </div>
                                : ""
                        }

                        <br/>
                        
                        {(auth.currentUser 
//                        && (auth.currentUser.email === "andras.szabo@idata.hu" 
//                            || auth.currentUser.email === "erika.buschor@@idata.hu"
//                            || auth.currentUser.email === "linda@idata.hu")
                        ) ?
                            <p>
                                <Link to={{pathname : "/editevent/new"}}>
                                    <Button color="info">Új esemény...</Button>
                                </Link>
                            </p>
                            : "" }

                        <p>
                            <Button onClick={this.logout}>Kijelentkezés</Button>
                        </p>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
