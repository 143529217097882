import { initializeApp } from 'firebase/app';

// Initialize Firebase
var firebaseConfig = {
    apiKey: "AIzaSyD6oZOnLLWoOCPe92E_q7ho8YDIGj7AOrk",
    authDomain: "pumi-app.firebaseapp.com",
    databaseURL: "https://pumi-app.firebaseio.com",
    projectId: "pumi-app",
    storageBucket: "",
    messagingSenderId: "418029189470"
};
let firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
