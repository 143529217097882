import React, { Component } from 'react';
import Event from './Event';
import firebaseApp from "./firebase";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, setDoc } from "firebase/firestore";
import { ClimbingBoxLoader } from 'react-spinners';

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            currentUser: getAuth().currentUser,
            loading : true,
            events : []
        };
    }

    componentDidMount() {
        var db = getFirestore(firebaseApp);
        var _this = this;
        const q = query(collection(db, "events"));
        getDocs(q).then(querySnapshot => {
            var newEvents = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data().title}`);
                var newEvent = doc.data();
                newEvent.id = doc.id;
                newEvent.startsAt = newEvent.startsAt.toDate();
                newEvents.push(newEvent);
            });
            _this.setState({events: newEvents, loading: false});
        });
    }

    render() {
            var currentUser = getAuth().currentUser;
            return (
                <div>
                    <ClimbingBoxLoader color="#007bff" loading={this.state.loading} />
                    {this.state.events.map(event => 
                        {return (event.visible || (event.organizer.uid === currentUser.uid)
                            || (currentUser.email === "andras.szabo@idata.hu")) ?
                            <React.Fragment key={event.id}><Event display="short" event={event}/><br/></React.Fragment>
                        : "" }
                        )}
                </div>
            );
    }
}

export default Events;
